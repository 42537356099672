import { TOKEN } from "@/constants/constants"
import { getLocalItem, removeItem, setLocalItem } from "./storage"
import { getToken, refreshToken } from "@/apis/auth.api"

const parseJWT = (jwt: string): JWT => {
  const base64 = jwt.split(".")[1]
  const jsonPayload = atob(base64)

  return JSON.parse(jsonPayload)
}

export const clearToken = () => {
  removeItem(TOKEN.ACCESS_TOKEN_KEY)
  removeItem(TOKEN.REFRESH_TOKEN_KEY)
}

export const getJWT = async (): Promise<{ token: string | null; decodedToken: JWT }> => {
  const code = new URLSearchParams(window.location.search).get("code")
  if (code) {
    clearToken()
  }

  let token = getLocalItem(TOKEN.ACCESS_TOKEN_KEY)

  if (!token) {
    if (!code) {
      throw new Error("No token found")
    }

    const getTokenResult = await getToken({
      client_id: `${process.env.CLIENT_ID}`,
      code,
      grant_type: "authorization_code",
    })

    setLocalItem(TOKEN.ACCESS_TOKEN_KEY, getTokenResult.access_token)
    setLocalItem(TOKEN.REFRESH_TOKEN_KEY, getTokenResult.refresh_token)

    return {
      token: getTokenResult.access_token,
      decodedToken: parseJWT(getTokenResult.access_token),
    }
  }

  let decodedToken = parseJWT(token)

  if (decodedToken.exp * 1000 < Date.now()) {
    const refreshTokenKey = getLocalItem(TOKEN.REFRESH_TOKEN_KEY)

    clearToken()

    if (!refreshTokenKey) throw new Error("Token expired, no refresh token.")

    const refreshResult = await refreshToken({
      client_id: `${process.env.CLIENT_ID}`,
      refresh_token: refreshTokenKey,
    })
    setLocalItem(TOKEN.ACCESS_TOKEN_KEY, refreshResult.access_token)
    token = refreshResult.access_token
    decodedToken = parseJWT(refreshResult.access_token)
  }

  return { token, decodedToken }
}