import { getLocalItem, setLocalItem } from "@/helpers/storage";
import { useState, useEffect } from "react";

const useLocalStorageWithEvent = (key: string) => {
  const [value, setValue] = useState(() => {
    try {
      return getLocalItem(key)
    } catch {
      return null;
    }
  });

  const updateValue = (newValue: any) => {
    try {
      const valueToStore = newValue instanceof Function ? newValue(value) : newValue;
      setValue(valueToStore);
      setLocalItem(key, valueToStore)

      window.dispatchEvent(new Event("localStorageChange"));
    } catch (error) {
      console.error("Error updating localStorage:", error);
    }
  };

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent | CustomEvent) => {
      if (event instanceof StorageEvent && event.key === key || event.type === "localStorageChange") {
        try {
          const newValue = JSON.parse(getLocalItem(key));
          setValue(newValue);
        } catch {
          setValue(null);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageChange", handleStorageChange as EventListener);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localStorageChange", handleStorageChange as EventListener);
    };
  }, [key]);

  return [value, updateValue];
};

export default useLocalStorageWithEvent;
