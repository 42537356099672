import { useEffect, useState, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { PATH } from "@/constants/paths";
import Loading from "@/components/Loading/Loading";
import { getJWT } from "@/helpers/auth";

interface ProtectedRouteProps {
  redirectPath?: string;
  children: ReactNode
}

const ProtectedRoute = ({
  redirectPath = PATH.FORBIDDEN,
  children
}: ProtectedRouteProps) => {
  const [isAuthed, setIsAuthed] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = (await getJWT()).token
        setIsAuthed(!!token)
      } catch (error) {
        window.location.href = `${process.env.VITE_GATEWAY_APP_URL}`
      } finally {
        setIsLoading(false)
      }
    }

    fetchToken()
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthed) {
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;