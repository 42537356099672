import axios from "./base.api"

const getToken = async (model: {
  client_id: string
  code: string
  grant_type: string
}): Promise<{ access_token: string; expires_in: number; refresh_token: string; id_token: string }> => {
  const response = await axios.post(`${process.env.VITE_GATEWAY_API_URL}/token`, model)
  return response.data.data
}

const refreshToken = async (model: {
  refresh_token: string
  client_id: string
}): Promise<{ access_token: string }> => {
  const response = await axios.post(`${process.env.VITE_GATEWAY_API_URL}/extend`, model)
  return response.data.data
}

export { getToken, refreshToken }
