export const PATH = {
  HOME: '/home',
  DEFAULT: '/extract',
  DATA_EXTRACTION: 'data-extraction',
  OUTPUT_PREVIEW: 'output-preview',
  ADMINSTRATION: '/administration',
  ALL_FILES: '/all-files',
  ACTIVITY_LOG: '/activity-log',
  FORBIDDEN: '/forbidden',
  AUTH: '/auth/sso',
};
