import { getJWT } from "@/helpers/auth";
import { useEffect, useState } from "react"

function usePermissions() {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(true)

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const { decodedToken } = await getJWT();
        if (!decodedToken?.scopes) {
          setPermissions([]);
        } else {
          setPermissions(decodedToken.scopes.split(" "));
        }
      } catch (error) {
        setPermissions([]);
      } finally {
        setIsLoadingPermissions(false)
      }
    };

    fetchPermissions();
  }, []);

  const hasPermission = (permission: string): boolean => permissions.includes(permission);

  return { hasPermission, isLoadingPermissions };
}

export default usePermissions