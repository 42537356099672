import { createBrowserRouter, Navigate } from "react-router-dom"
import { PATH } from "@/constants/paths"
import { lazy, Suspense } from "react"
import ExtractForm from "@/components/ExtractForm/ExtractForm"
import Loading from "@/components/Loading/Loading"
import DataExtraction from "@/components/DataExtraction/DataExtraction"
import OutputPreview from "@/components/OutputPreview/OutputPreview"
import AllFilesWrapper from "@/components/AllFilesWrapper/AllFilesWrapper"
import ProtectedRoute from "./ProtectedRoute"

const AllFilesPage = lazy(() => import("@/pages/AllFiles/AllFiles"))
const ExtractPage = lazy(() => import("@/pages/Extract/Extract"))
const ForbiddenPage = lazy(() => import("@/pages/PageForbidden/PageForbidden"))
const NotFoundPage = lazy(() => import("@/pages/PageNotFound/PageNotFound"))
const SingleSignOnPage = lazy(() => import("@/pages/SingleSignOn/SingleSignOn"))

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={PATH.DEFAULT} />,
  },
  {
    path: PATH.DEFAULT,
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <ExtractPage />
        </ProtectedRoute>
      </Suspense>
    ),
    errorElement: <div>Error</div>,
    children: [
      {
        index: true,
        element: (
          <ExtractForm />
        ),
      },
      {
        path: PATH.DATA_EXTRACTION,
        element: (
          <DataExtraction />
        ),
      },
      {
        path: PATH.OUTPUT_PREVIEW,
        element: (
          <OutputPreview />
        ),
      },
    ],
  },

  {
    path: PATH.ALL_FILES,
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <AllFilesPage />
        </ProtectedRoute>
      </Suspense>
    ),
    errorElement: <div>Error</div>,
    children: [
      {
        index: true,
        element: <AllFilesWrapper />,
      },
    ],
  },

  {
    path: PATH.AUTH,
    element:
      (
        <Suspense fallback={<Loading />}>
          <SingleSignOnPage />
        </Suspense>
      ),
    errorElement: <div>Error</div>,
  },

  {
    path: PATH.FORBIDDEN,
    element: (
      <Suspense fallback={<Loading />}>
        <ForbiddenPage />
      </Suspense>
    ),
    errorElement: <div>Error</div>,
  },

  {
    path: "*",
    element: (
      <Suspense fallback={<Loading />}>
        <NotFoundPage />
      </Suspense>
    ),
    errorElement: <div>Error</div>,
  },
])
